import router from "@/router";
import { removeHiddenMenus, getFullPath, getAllActiveMenuIds } from "@/utils/menu";

export default {
  namespaced: true,
  state: {
    menuList: [],
    dynamicMenuRoutes: [],
    menuActiveName: "",
    headName: null,
  },
  getters: {
    breadcrumbs: ({ menuList, menuActiveName }) => {
      if (menuList && menuList.length > 1) {
        return getFullPath(menuList, menuActiveName, "id");
      }
      return [];
    },
    sidebarMenuList: ({ menuList }) => {
      return removeHiddenMenus(menuList);
    },
    activeMenu: ({ menuList, menuActiveName }) => {
      const activeMenuIds = getAllActiveMenuIds(menuList, menuActiveName);
      return activeMenuIds[activeMenuIds.length - 1] || menuActiveName;
    },
  },
  mutations: {
    updateMenuList(state, list) {
      state.menuList = list;
    },
    updateDynamicMenuRoutes(state, list) {
      state.dynamicMenuRoutes = list;
    },
    updateMeta(state, val) {
      state.headName = val.headName;
    },
    updateMenuActiveName(state, name) {
      state.menuActiveName = name;
    },
  },
};
