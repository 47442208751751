<template>
  <el-button native-type="submit" type="primary" icon="el-icon-search" plain class="search">Search</el-button>
</template>

<script>
export default {
  name: "ElSearchButton",
};
</script>
<style lang="scss">
button.search,
button.search:focus {
  // color: #fff !important;
}
</style>
