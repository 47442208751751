module.exports = file => () => {
  try {
    const replaced = file.replace(/(:([a-zA-Z]+)(\??))/g, function ($0, $1, $2, $3) {
      if ($3) {
        return "[" + $2 + "$]";
      }
      return "[" + $2 + "]";
    });
    return import(/* webpackChunkName: "group-main" */ "@/views/" + replaced);
  } catch (error) {
    return null;
  }
};
