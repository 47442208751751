export default {
  namespaced: true,
  state: {
    // 页面文档可视高度(随窗口改变大小)
    documentClientHeight: 0,
    // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
    sidebarLayoutSkin: "light",
    // 侧边栏, 折叠状态
    sidebarFold: false,
    // 内容, 是否需要刷新
    contentIsNeedRefresh: false,
  },
  mutations: {
    updateDocumentClientHeight(state, height) {
      state.documentClientHeight = height;
    },
    updateSidebarLayoutSkin(state, skin) {
      state.sidebarLayoutSkin = skin;
    },
    updateSidebarFold(state, fold) {
      state.sidebarFold = fold;
    },
    updateContentIsNeedRefresh(state, status) {
      state.contentIsNeedRefresh = status;
    },
  },
};
