import { Notification, Message } from "element-ui";
import axios from "axios";
import router from "@/router";
import { getToken, clearLoginInfo } from "@/utils/auth";
import qs from "query-string";

let errorLoading = false;

export function createInstance() {
  return axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT || "/api",
    paramsSerializer: function (params) {
      return qs.stringify(params);
    },
    withCredentials: false,
  });
}

const instance = createInstance();

instance.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => {
    if (response.data) {
      return response.data;
    } else {
      return response;
    }
  },
  error => {
    const expectedErrors = error.response && error.response.status >= 400 && error.response.status < 500;
    if (!expectedErrors) {
      console.error("unexpected err", error);
      if (!errorLoading) {
        errorLoading = true;
        Message.error({
          message: "Unknown error, please try again later",
          onClose: () => {
            errorLoading = false;
          },
        });
      }
      return Promise.reject(error);
    } else {
      if (error.response.status === 401) {
        console.log(401);
        clearLoginInfo();
        router.push({ name: "login" });
      } else if (error.response.status == 403) {
        console.error("403", error);
        Notification({
          title: "权限不足",
          message: "您没有访问权限",
          position: "top-right",
          type: "error",
          duration: 2000,
          offset: 100,
        });
      }
      return Promise.reject(error);
    }
  },
);

export default instance;
