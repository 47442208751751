import router from "@/router";
import { createInstance } from "@/utils/http";

// Http 实例
const httpInstance = createInstance();

/**
 * 检查登录信息
 */
export function checkIsLogin() {
  return localStorage.getItem("token") !== null;
}

/**
 * 设置登录信息
 */
export function saveLoginInfo(access_token) {
  localStorage.setItem("token", access_token);
}

/**
 * 获取登录令牌
 */
export function getToken() {
  return localStorage.getItem("token");
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  localStorage.removeItem("token");
  router.options.isAddDynamicMenuRoutes = false;
}

/**
 * 登录
 */
export async function doLogin(formData) {
  try {
    const { data } = await httpInstance.postForm("/login", formData);
    const { success, data: token } = data;
    if (success) {
      saveLoginInfo(token);
    }
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error.response);
  }
}

/**
 * 登出
 */
export async function doLogout(cb) {
  await httpInstance.post("/logout");
  clearLoginInfo();
  cb();
}
