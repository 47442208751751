export default {
  namespaced: true,
  state: {
    roleList: [],
  },
  getters: {
    roleList: state => {
      return state.roleList;
    },
  },
  mutations: {
    initRoles(state, roles) {
      state.roleList = roles;
    },
    addOne(state, role) {
      state.roleList = [...state.roleList, role];
    },
    updateOne(state, role) {
      const roleList = state.roleList;
      const old = roleList.find(i => i.id === role.id);
      const idx = roleList.indexOf(old);
      roleList[idx] = role;
      state.roleList = [...roleList];
    },
    deleteOne(state, roleId) {
      const filtered = state.roleList.filter(i => i.id !== roleId);
      state.roleList = { ...filtered };
    },
  },
};
