import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import common from "./modules/common";
import menu from "./modules/menu";
import user from "./modules/user";
import role from "./modules/role";

Vue.use(Vuex);
Vue.config.devtools = process.env.NODE_ENV === "development";

export default new Vuex.Store({
  modules: {
    common,
    menu,
    user,
    role,
  },
  mutations: {},
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(val) {
        return {
          // 只存储布局设置
          common: {
            sidebarFold: val.common.sidebarFold,
          },
        };
      },
    }),
  ],
});
