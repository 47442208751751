import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";

import "./plugins/element.js";
import "./assets/css/global.css";
import "./assets/scss/index.scss";

import "./plugins/cookie.js";
import "./plugins/http.js";
import "./plugins/auth.js";
import "./plugins/svg.js";
import "./plugins/searchbutton";
import "./plugins/captcha.js";

Vue.config.productionTip = false;

export default new Vue({
  store,
  router,
  render: h => h(App),
}).$mount("#app");
