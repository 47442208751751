<template>
  <div class="captcha">
    <img width="100%" @click="() => fetchCaptcha()" :src="image" />
  </div>
</template>

<script>
export default {
  name: "captcha",
  data() {
    return {
      image: "",
    };
  },
  mounted() {
    this.fetchCaptcha();
  },
  methods: {
    refresh() {
      this.fetchCaptcha();
    },
    async fetchCaptcha() {
      const { code, msg, data } = await this.$http.get("/getCaptcha");
      if (code === 200) {
        this.image = data.image;
        this.$emit("key", data.key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.captcha {
  img {
    cursor: pointer;
  }
}
</style>
