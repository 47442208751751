var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "captcha"
  }, [_c('img', {
    attrs: {
      "width": "100%",
      "src": _vm.image
    },
    on: {
      "click": function () {
        return _vm.fetchCaptcha();
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }