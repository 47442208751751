import http from "@/utils/http";
import Vue from "vue";
import Router from "vue-router";
import { checkIsLogin, clearLoginInfo } from "@/utils/auth";

// 拦截路由 push 异常
const _push = Router.prototype.push;
Router.prototype.push = function push(location) {
  return _push.call(this, location).catch(err => console.debug("push failed", err));
};

Vue.use(Router);

// 通用路由
const globalRoutes = [
  {
    path: "/404",
    component: () => import("@/views/404"),
    name: "404",
    meta: { title: "404 Not Found" },
  },
  {
    path: "/login",
    component: () => import("@/views/login"),
    name: "login",
    meta: { title: "login" },
  },
  {
    path: "/forgotpasswd",
    component: () => import("@/views/forgotpasswd"),
    name: "forgotpasswd",
    meta: { title: "forgotpasswd" },
  },
  {
    path: "/resetpasswd",
    component: () => import("@/views/resetpasswd"),
    name: "resetpasswd",
    meta: { title: "resetpasswd" },
  },
];

// 嵌套路由
const mainRoute = {
  path: "/",
  exact: true,
  component: () => import("@/layout/default"),
  name: "main",
  redirect: { name: "home" },
  meta: { title: "The main layout" },
  children: [
    {
      path: "home",
      component: () => import("@/views/home"),
      name: "home",
      meta: { title: "home", id: "0" },
    },
  ],
};

const createRouter = ignoreMainRoute => {
  return new Router({
    mode: process.env.NODE_ENV === "development" ? "hash" : "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    isAddDynamicMenuRoutes: false,
    routes: ignoreMainRoute ? [...globalRoutes] : [...globalRoutes, mainRoute],
  });
};

export function resetRouter() {
  const newRouter = createRouter(true);
  router.matcher = newRouter.matcher;
}

// 路由对象
const router = createRouter();

// 路由守卫
// 在每次路由切换之前进行一系列的检查和操作，包括设置页面标题、检查登录状态、重置路由、填充主路由等
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = "EStar SSO - " + to.meta.title;
  }
  if (router.options.isAddDynamicMenuRoutes || globalRoutes.find(i => i.path === to.path)) {
    //之前已填充主路由，或者是 404 等全局路由的情况，直接放行
    next();
  } else {
    if (!checkIsLogin()) {
      //主路由必须登录，若令牌为空，直接跳转到登录页
      next({ name: "login", replace: true });
    } else {
      //注：这里用 try...catch 保证 next() 方法最终被执行，否则可能会导致浏览器插件路由变更的监听失效
      try {
        resetRouter();
        await fillMainRoutes();
        //重置路由后，必须 replace 新的路由栈才生效
        next({ ...to, replace: true });
      } catch (err) {
        console.log("Failed to fill routes", err);
        next();
      }
    }
  }
});

// 填补菜单路由
const fillMainRoutes = async () => {
  const {
    code,
    data: { menuList, permissions },
  } = await http.get("/getNavMenuResult");
  if (code === 200) {
    localStorage.setItem("menuList", JSON.stringify(menuList || "[]"));
    localStorage.setItem("permissions", JSON.stringify(permissions || "[]"));
    const menus = flatAll(menuList);
    let routeObj = {
      default: { ...mainRoute },
    };
    for (let i in menus) {
      const item = menus[i];
      if (item.path) {
        const routeLayout = item.metaInfo?.layout || "default";
        if (!routeObj[routeLayout]) {
          routeObj[routeLayout] = {
            path: "/",
            exact: true,
            component: () => import("@/layout/" + routeLayout),
            name: routeLayout,
            redirect: { name: "home" },
            children: [],
          };
        }
        routeObj[routeLayout].children.push({
          name: item.path,
          path: item.path,
          exact: true,
          component: _import(`${item.path}`),
          meta: {
            id: item.id,
            title: item.name,
            isDynamic: true,
            isTab: false,
            ...item.metaInfo,
          },
        });
      }
    }
    // 副作用
    router.options.isAddDynamicMenuRoutes = true;
    const routeList = [...Object.values(routeObj), { path: "*", redirect: { name: "404" } }];
    routeList.forEach(route => {
      router.addRoute(route);
    });
    localStorage.setItem("dynamicMenuRoutes", JSON.stringify(routeList.flatMap(i => i.children || [])));
  } else {
    localStorage.setItem("menuList", "[]");
    localStorage.setItem("permissions", "[]");
  }
};

//懒加载引入路由。 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require("./import-" + process.env.NODE_ENV);

// 将树型元素转成扁平元素
function flatAll(list) {
  function flat(x, z = []) {
    z.push(x);
    if (x.children) {
      x.children.forEach(child => flat(child, z));
    }
    return z;
  }
  return list.flatMap(i => flat(i));
}

// 重新填充路由（如菜单发生变化）
export function refillRoutes() {
  return new Promise((resolve, reject) => {
    resetRouter();
    fillMainRoutes()
      .then(function () {
        resolve();
      })
      .catch(function () {
        reject(new Error("fail get main routes"));
      });
  });
}

export default router;
