var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-button', {
    staticClass: "search",
    attrs: {
      "native-type": "submit",
      "type": "primary",
      "icon": "el-icon-search",
      "plain": ""
    }
  }, [_vm._v("Search")]);

}
var staticRenderFns = []

export { render, staticRenderFns }